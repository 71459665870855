import React from 'react';
import EF from '../img/ef.webp';
import ET2 from '../img/et2.webp';
import Henry from '../img/Henry.webp';
import UPSH from '../img/UpskillingH.webp';
import UBA from '../img/UBA.webp';

const about1 = "¡Hola! ¡Bienvenido a mi web! Soy Gonzalo, Desarrollador Web Full Stack con un nivel de inglés B2 y Técnico Electrónico - Upper Intermediate y portugués intermedio. Tengo experiencia con varios lenguajes, entre ellos:";
const technologies = 'HTML5, CSS3, Tailwind CSS, JavaScript, TypeScript, React, Redux, Node.js, Express, Next.js, MongoDB, MySQL y PostgreSQL.';
const about2 = "Me destaco por mi capacidad para trabajar en equipo, organizar tareas, pensar de manera creativa y resolver problemas de forma autónoma, todo respaldado por una comunicación efectiva.";
const about3 = "A lo largo de mi carrera, trabajé en proyectos que incluyen funcionalidades como consumo de APIs, gestión de usuarios, panel de administración exclusivo, administración de stock, función de favoritos, integración con pasarelas de pago (MercadoPago), carruseles de imágenes, autenticación con terceros y la implementación de la metodología SCRUM. ¡Espero que disfrutes explorando mi trabajo!";

const certifications = [
  {
    url: "https://www.efset.org/cert/qM3RBv",
    img: EF,
    alt: "Certificado de Inglés - EF SET",
    title: "Certificado de Inglés B2 - EF SET",
    ariaLabel: "Ver certificado de Inglés B2 en EF SET",
  },
  {
    url: "https://certificates.soyhenry.com/cert?id=749046a9-0e89-4d0f-90ae-7574c3757708",
    img: Henry,
    alt: "Certificado Full Stack Developer - Henry",
    title: "Certificado de Desarrollador Full Stack - Henry",
    ariaLabel: "Ver certificado de Desarrollador Full Stack en Henry",
  },
  {
    url: "https://certificates.soyhenry.com/new-cert?id=7c0a8221da10e20ca9eaafa0c04325f1c9205e1e2a13c1607c644f71ff9bb0f3",
    img: UPSH,
    alt: "Certificado Upskilling Henry - Desarrollo Web Avanzado",
    title: "Certificado de Upskilling en Desarrollo Web - Henry",
    ariaLabel: "Ver certificado de Upskilling en Henry",
    height: 75,
  },
  {
    url: "https://drive.google.com/file/d/1akP5I1ikdJtCXSctHvYRGAZcD7FryVZi/view",
    img: UBA,
    alt: "Certificado de Portugués - Universidad de Buenos Aires (UBA)",
    title: "Certificado de Idioma Portugués - UBA",
    ariaLabel: "Ver certificado de Portugués de la Universidad de Buenos Aires",
  },
  {
    url: "https://drive.google.com/file/d/1I2_OL4qSw_li_aDsF5xw7LJ55sqy6RMH/view",
    img: ET2,
    alt: "Certificado Técnico Electrónico - Instituto Técnico",
    title: "Certificado de Técnico Electrónico - Instituto Técnico",
    ariaLabel: "Ver certificado de Técnico Electrónico del Instituto Técnico",
  },
];

class About extends React.Component {
  render() {
    return (
      <section 
        id="about" 
        className="about-mf sect-pt4 route"
        aria-labelledby="about-title"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="about-me pt-4 pt-md-0">
                  <h2 id="about-title" className="title-left p-2">Sobre Mí</h2>
                  <p className="lead p-2 w-title">{about1}</p>
                  <p className="lead p-2 w-title" style={{ fontWeight: 'bold' }}>{technologies}</p>
                  <p className="lead p-2 w-title">{about2}</p>
                  <p className="lead p-2 w-title">{about3}</p>
                </div>
                <br />
                <div className="about-me pt-4 pt-md-0">
                  <h6 
                    id="certifications-title"
                    className="title-left p-2"
                    aria-label="Certificaciones obtenidas"
                  >
                    Certificaciones
                  </h6>
                  <div className="socials">
                    <ul role="list">
                      {certifications.map(({ url, img, alt, title, ariaLabel, height = 'auto' }, index) => (
                        <li key={index} role="listitem">
                          <a 
                            href={url} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            title={title}
                            aria-label={ariaLabel}
                          >
                            <img 
                              src={img} 
                              alt={alt} 
                              title={title} 
                              style={{ cursor: 'pointer' }} 
                              height={height} 
                              loading="lazy"
                            />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
