import React, { Component } from 'react';

class BackToTop extends Component {
  state = {
    isVisible: false
  };

  handleScroll = () => {
    if (window.pageYOffset > 100) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { isVisible } = this.state;
    return (
      isVisible && (
        <button
          className="back-to-top animated fadeIn"
          onClick={this.scrollToTop}
          style={{ position: 'fixed', bottom: '30px', right: '30px' }}
        >
          <i className="fa fa-chevron-up"></i>
        </button>
      )
    );
  }
}

export default BackToTop;
