/* eslint-disable react/prop-types */
'use client'
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import s from './BotonWsp.module.css'; // Importar los estilos del módulo CSS

const userData = { 
  codigoPais: 54,
  contact: 1136317470,
  textBoton:'¡Contáctame!',
  textoPredefinido :`Hola, me gustaria saber mas sobre ...`
};

const BotonWsp = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  const enviar = `https://wa.me/+${userData.codigoPais}${userData.contact}?text=${userData.textoPredefinido}`;

  return (
      <article id='whats' className={s.fixedbtn}>
        <a 
          href={enviar} 
          title="Boton Whatsapp" 
          target="_blank" 
          rel="noopener noreferrer"
          className={`${s.flex} ${s.itemsCenter} ${s.justifyCenter}`} 
          onMouseEnter={handleMouseEnter} 
          onMouseLeave={handleMouseLeave} 
          aria-label="Contact via WhatsApp"
        >
          <FaWhatsapp className={`${s.textWhite} ${s.text3xl}`} />
          {isHovered && <h3 className={s.marginLeft2}>{userData.textBoton}</h3>}
        </a>
      </article>
  );
}

export default BotonWsp;
