import React from "react";
import imageOverlay from '../img/PortfolioBk.webp';

class Contact extends React.Component {
  render() {
    return (
      <div id="contact" className="paralax-mf footer-paralax bg-image sect-mt4 route"
        style={{ backgroundImage: "url(" + imageOverlay + ")" }}
      >
        <div className="overlay-mf"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="contact" className="box-shadow-full">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="title-box-2">
                        <h5 className="title-left">Enviar un Mensaje</h5>
                      </div>
                      <div>
                        <form
                          action="https://formspree.io/f/xnqybkro"
                          method="POST"
                          className="contactForm"
                        >
                          <div id="sendmessage">
                            ¡Tu mensaje ha sido enviado. ¡Gracias!
                          </div>
                          <div id="errormessage"></div>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  id="name"
                                  placeholder="Tu Nombre"
                                  data-rule="minlen:4"
                                  data-msg="Por favor, ingresa al menos 4 caracteres"
                                  required
                                  aria-label="Nombre"
                                  title="Tu Nombre"
                                />
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  id="email"
                                  placeholder="Tu Correo Electrónico"
                                  data-rule="email"
                                  data-msg="Por favor, ingresa un correo electrónico válido"
                                  required
                                  aria-label="Correo Electrónico"
                                  title="Tu Correo Electrónico"
                                />
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="subject"
                                  id="subject"
                                  placeholder="Asunto"
                                  data-rule="minlen:4"
                                  data-msg="Por favor, ingresa al menos 8 caracteres en el asunto"
                                  required
                                  aria-label="Asunto"
                                  title="Asunto"
                                />
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  name="message"
                                  rows="5"
                                  data-rule="required"
                                  data-msg="Por favor, escribe algo para nosotros"
                                  placeholder="Mensaje"
                                  required
                                  aria-label="Mensaje"
                                  title="Mensaje"
                                ></textarea>
                                <div className="validation"></div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <button type="submit" className="button button-a button-big button-rouded" aria-label="Enviar mensaje">Enviar Mensaje</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="title-box-2 pt-4 pt-md-0">
                        <h5 className="title-left">Ponte en Contacto</h5>
                      </div>
                      <div className="more-info">
                        <p className="lead">
                          Si deseas ponerte en contacto, hablar sobre una colaboración de proyecto o simplemente saludar.
                          <br />
                          Simplemente completa el formulario y envíame un correo.
                        </p>
                        <ul className="list-ico">
                          <li><label className="ion-ios-location" aria-hidden="true"></label> Buenos Aires, Argentina</li>
                          <li><label className="ion-ios-telephone" aria-hidden="true"></label> (+54) 11-3631-7470</li>
                          <li><label className="ion-email" aria-hidden="true"></label> gonzalotorresgrau@gmail.com</li>
                        </ul>
                        <div className="socials">
                          <h4>Redes Sociales</h4>
                          <br/>
                          <ul>
                            <li>
                              <a 
                                href="https://github.com/gtorresgrau?tab=overview&from=2022-07-01&to=2022-07-08" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                title="Ver perfil de GitHub"
                                aria-label="Ver perfil de GitHub"
                              >
                                <label className="ico-circle"><i className="ion-social-github" aria-hidden="true"></i></label>
                              </a>
                            </li>
                            <li>
                              <a 
                                href="https://www.linkedin.com/in/gonzalotorresgrau/" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                title="Ver perfil de LinkedIn"
                                aria-label="Ver perfil de LinkedIn"
                              >
                                <label className="ico-circle"><i className="ion-social-linkedin" aria-hidden="true"></i></label>
                              </a>
                            </li>
                            <li>
                              <a 
                                href="https://api.whatsapp.com/send?phone=5491136317470" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                title="Enviar mensaje por WhatsApp"
                                aria-label="Enviar mensaje por WhatsApp"
                              >
                                <label className="ico-circle"><i className="ion-social-whatsapp" aria-hidden="true"></i></label>
                              </a>
                            </li>
                            <li>
                              <a 
                                href="https://t.me/gonzalotg" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                title="Enviar mensaje por Telegram"
                                aria-label="Enviar mensaje por Telegram"
                              >
                                <label className="ico-circle"><i className="ion-ios-paperplane" aria-hidden="true"></i></label>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Contact;
