import React, { useEffect, useState } from "react";
import bigImage from "../img/PortfolioBk.webp";

const FadeInOutEffect = () => {
  const [text, setText] = useState("");
  const [isFadingOut, setIsFadingOut] = useState(false);
  const strings = [
    "Front End Developer",
    "Back End Developer",
    "Técnico Electrónico",
  ];
  let currentStringIndex = 0;

  useEffect(() => {
    const changeText = () => {
      setIsFadingOut(true);

      setTimeout(() => {
        setText(strings[currentStringIndex]);
        setIsFadingOut(false);
        currentStringIndex = (currentStringIndex + 1) % strings.length;
      }, 500);

      setTimeout(changeText, 3000);
    };

    changeText();
  }, []);

  return (
    <span 
      className={`text-slider ${isFadingOut ? "fade-out" : "fade-in"}`}
      aria-live="polite"
    >
      {text}
    </span>
  );
};

const Intro = () => {
  return (
    <section 
      id="home" 
      className="intro route bg-image"
      aria-labelledby="intro-title"
    >
      <div
        className="intro-content display-table"
        style={{ backgroundImage: `url(${bigImage})`, backgroundSize:'cover' }}
      >
        {/* Imagen de fondo con texto alternativo oculto para accesibilidad */}
        <div className="visually-hidden">
          Imagen de fondo representativa del portafolio de Gonzalo Torres Grau.
        </div>

        <div className="table-cell" style={{ backdropFilter: "blur(10px)" }}>
          <div className="container">
            <h2 id="intro-title" className="intro-title mb-4">
              Hola, soy Gonzalo
            </h2>
            <p className="intro-subtitle">
              <strong className="text-slider">
                <FadeInOutEffect />
              </strong>
            </p>
            <p className="pt-3">
              <a 
                className="btn btn-primary btn js-scroll px-4" 
                href="#work"
                role="button"
                title="Ver mis trabajos"
                aria-label="Ir a la sección de trabajos"
              >
                Trabajos
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
