import React from "react";
import $ from 'jquery';
import { websites } from '../constants/data';
import 'lightbox2/dist/css/lightbox.min.css';
import lightbox from 'lightbox2';

class Portfolio extends React.Component {
  componentDidMount() {
    // Initialize lightbox after the component has mounted
    lightbox.init();
  }

  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route" aria-labelledby="portfolio-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a" id="portfolio-section">Portafolio</h3>
                <h1 className="w-title">¡Hola! Aquí puedes ver algunos de los sitios web que desarrollé para mis clientes y para mí. ¡Espero que te gusten!</h1>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {websites && websites.length > 0 ? (
              websites.map((web, key) => (
                <article className="col-md-4" key={key} aria-labelledby={`work-title-${key}`}>
                  <div className="work-box">
                    <a href={web.portada} data-lightbox={web.lightBox} title={`Ver el proyecto de ${web.title}`} aria-label={`Ver el proyecto de ${web.title}`} style={{ textDecoration: "none" }}>
                      <div className="work-img">
                        <img 
                          src={web.portada} 
                          alt={web.title} 
                          className="img-fluid" 
                          loading="lazy" 
                          title={web.title} 
                          aria-describedby={`work-description-${key}`}
                        />
                      </div>
                      <div className="work-content" style={{ textDecoration: "none" }}>
                        <div className="row">
                          <div className="col-sm-8">
                            <h2 className="w-title" id={`work-title-${key}`} style={{ textDecoration: "none" }}>{web.title}</h2>
                            <h2 className="small" style={{ textDecoration: "none" }}>{web.subtitle}</h2>
                            <h2 className="small">{web.author}</h2>
                            <div className="w-more">
                              <label className="w-ctegory">{web.tech}</label>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="w-like">
                              <label className="ion-ios-plus-outline" aria-label="Añadir a favoritos"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    {web.image && web.image.map((img, key) => (
                      <a href={img} data-lightbox={web.lightBox} style={{ display: "none" }} key={key} title={`Ver detalle de ${web.title}`} aria-label={`Ver detalle de ${web.title}`}>
                        Características
                      </a>
                    ))}
                    <div>
                      <button 
                        style={{ margin: "5px", borderRadius: "25px", borderColor: "#0078ff" }} 
                        aria-label={`Ir al enlace de ${web.title}`}
                      >
                        <a href={web.link} target="_blank" rel="noreferrer" title={`Enlace a ${web.title}`} style={{ textDecoration: "none" }}>ENLACE</a>
                      </button>
                      {web.download && (
                        <button 
                          style={{ margin: "5px", borderRadius: "25px", borderColor: "#0078ff" }} 
                          aria-label={`Descargar ${web.title}`}
                        >
                          <a href={web.download} target="_blank" rel="noreferrer" title={`Descargar ${web.title}`} style={{ textDecoration: "none" }}>
                            <i className="ion-android-download" aria-hidden="true" />
                          </a>
                        </button>
                      )}
                    </div>
                  </div>
                </article>
              ))
            ) : (
              <div>No websites available</div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
