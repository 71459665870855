import React from 'react';
import ReactDOM from 'react-dom/client';

// Import CSS in order
import 'normalize.css';
import './animate.css';
import 'bootstrap/dist/css/bootstrap.css';
import './img/icons/css/ionicons.css';
import './img/font-awesome/css/font-awesome.css';
import 'lightbox2/dist/css/lightbox.min.css';
import './style.css';

// Import Components
import Navbar from './components/navbar.jsx';
import Intro from './components/intro.jsx';
import About from './components/about.jsx';
import Portfolio from './components/portfolio.jsx';
import Contact from './components/contact.jsx';
import BackToTop from './components/back-top.jsx';
//import Preloader from './components/preloader';
import BotonWsp from './components/BotonWSP/BotonWsp.jsx';

// React Entry Point (Using React 18's root API)
const App = () => (
  <>
    <Navbar />
    <Intro />
    <Portfolio />
    <About />
    <Contact />
    <BackToTop />
    {/*<Preloader />*/}
    <BotonWsp />
  </>
);

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
