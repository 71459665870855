import React, { Component } from "react";
import logo1 from "../img/male1.webp";
import logo2 from "../img/male.webp";

class Navbar extends Component {
  state = {
    logo: logo1,
    navbarClass: "navbar-expand-md navbar-trans",
    isNavbarExpanded: false, // Solo afecta en pantallas pequeñas
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const logo = window.pageYOffset > 50 ? logo2 : logo1;
    const navbarClass = window.pageYOffset > 50 ? "navbar-reduce" : "navbar-trans";
    this.setState({ logo, navbarClass });
  };

  toggleNavbar = () => {
    if (window.innerWidth < 768) {
      this.setState((prevState) => ({
        isNavbarExpanded: !prevState.isNavbarExpanded,
      }));
    }
  };

  smoothScroll = (event, targetId) => {
    event.preventDefault();
    const target = document.querySelector(targetId);
    if (target) {
      const navHeight = document.querySelector("nav").offsetHeight;
      window.scrollTo({
        top: target.offsetTop - navHeight + 5,
        behavior: "smooth",
      });
    }
    if (window.innerWidth < 768) {
      this.setState({ isNavbarExpanded: false });
    }
  };

  render() {
    const { logo, navbarClass, isNavbarExpanded } = this.state;

    return (
      <nav
        className={`navbar navbar-b ${navbarClass} navbar-expand-md fixed-top`}
        id="mainNav"
        aria-label="Menú de navegación principal"
      >
        <div className="container">
          <a
            className="navbar-brand"
            href="#inicio"
            title="Ir al inicio"
            aria-label="Ir al inicio"
          >
            <img
              src={logo}
              alt="Logo de Gonzalo Torres Grau"
              loading="lazy"
              style={{ maxWidth: "100px" }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleNavbar}
            aria-controls="navbarDefault"
            aria-expanded={isNavbarExpanded}
            aria-label="Abrir o cerrar menú de navegación"
            title="Abrir o cerrar menú"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div
            className={`collapse navbar-collapse justify-content-end ${
              isNavbarExpanded ? "show" : ""
            }`}
            id="navbarDefault"
          >
            <ul className="navbar-nav">
              {[
                { href: "#home", label: "Inicio" },
                { href: "#about", label: "Sobre Mí" },
                { href: "#work", label: "Trabajos" },
                { href: "#contact", label: "Contacto" },
              ].map(({ href, label }) => (
                <li className="nav-item" key={href}>
                  <a
                    className="nav-link"
                    href={href}
                    onClick={(e) => this.smoothScroll(e, href)}
                    aria-label={`Ir a la sección de ${label}`}
                    title={label}
                  >
                    {label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
