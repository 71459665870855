
import CCW1 from "../img/CCW/Busqueda.webp";
import CCW2 from "../img/CCW/Contacto.webp";
import CCW3 from "../img/CCW/Detalle.webp";
import CCW4 from "../img/CCW/Home.webp";
import CCW5 from "../img/CCW/Newsletter.webp";
import CCW6 from "../img/CCW/Opiniones.webp";
import CCW7 from "../img/CCW/Responsive1.webp";
import CCW8 from "../img/CCW/Responsive2.webp";

import Alarma1 from "../img/AlarmaGeografica/Home.png";
import Alarma2 from "../img/AlarmaGeografica/Caracteristicas.png";
import Alarma3 from "../img/AlarmaGeografica/ComoSoy.png";
import Alarma4 from "../img/AlarmaGeografica/Descargas.png";
import Alarma5 from "../img/AlarmaGeografica/Paraque.png";
import Alarma6 from "../img/AlarmaGeografica/Contacto.png";
import Alarma7 from "../img/AlarmaGeografica/Responsive1.png";
import Alarma8 from "../img/AlarmaGeografica/Responsive2.png";

import residencia1 from "../img/ResidenciaSalomon/1.png";
import residencia2 from "../img/ResidenciaSalomon/2.png";
import residencia3 from "../img/ResidenciaSalomon/3.png";
import residencia4 from "../img/ResidenciaSalomon/4.png";
import residencia5 from "../img/ResidenciaSalomon/5.png";
import residencia6 from "../img/ResidenciaSalomon/6.png";

import DTG from "../img/ODTG/DTG.png";

import blum1 from "../img/Blum/blum1.png";
import blum2 from "../img/Blum/blum2.png";
import blum3 from "../img/Blum/blum3.png";
import blum4 from "../img/Blum/blum4.png";
import blum5 from "../img/Blum/blum5.png";

import eshop1 from "../img/eshop/eshop1.png";
import eshop2 from "../img/eshop/eshop2.png";
import eshop3 from "../img/eshop/eshop3.png";
import eshop4 from "../img/eshop/eshop4.png";

import ocular1 from "../img/Ocular/ocular1.png";
import ocular2 from "../img/Ocular/ocular2.png";
import ocular3 from "../img/Ocular/ocular3.png";
import ocular4 from "../img/Ocular/ocular4.png";
import ocular5 from "../img/Ocular/ocular5.png";

import Sermar1 from '../img/Sermar/home.png'
import Sermar2 from '../img/Sermar/nosotros.png'
import Sermar3 from '../img/Sermar/servicios.webp'
import Sermar4 from '../img/Sermar/contacto.webp'
import Sermar5 from '../img/Sermar/galeria1.webp'
import Sermar6 from '../img/Sermar/responsive.webp'
import Sermar7 from '../img/Sermar/responsive2.webp'
import Sermar8 from '../img/Sermar/responsive3.webp'
import Sermar9 from '../img/Sermar/responsive4.webp'


import Romihsale1 from "../img/romihsale/1.webp";
import Romihsale2 from "../img/romihsale/2.webp";
import Romihsale3 from "../img/romihsale/3.webp";
import Romihsale4 from "../img/romihsale/4.webp";
import Romihsale5 from "../img/romihsale/5.webp";
import Romihsale6 from "../img/romihsale/6.webp";
import Romihsale7 from "../img/romihsale/7.webp";
import Romihsale8 from "../img/romihsale/8.webp";

import central1 from "../img/central/central1.png";
import central2 from "../img/central/central2.png";
import central3 from "../img/central/central3.png";
import central4 from "../img/central/central4.png";
import central5 from "../img/central/central5.png";
import central6 from "../img/central/central6.png";
import central7 from "../img/central/central7.png";


export const websites = [
        {
            title:"Blüm Club",
            lightBox:'blum',
            subtitle:"Landing WebSite",
            author:'OWN',
            tech:"REACT TAILWINDCSS NEXT",
            portada:blum1,
            link:"https://blumclub.com.ar",
            download:"",
            image:[blum1,blum2,blum3,blum4,blum5]
        },
       {
        title:"Centro Comercial Wilde",
        lightBox:'CCW',
        subtitle:"Landing Website",
        author:'PROGRAMUNDO',
        tech:"TYPESCRIP TAILWINDCSS REACT NEXT MONGODB",
        portada:CCW1,
        link:"https://centrocomercialwilde.com/",
        download:"",
        image:[CCW2,CCW3,CCW4,CCW5,CCW6,CCW7,CCW8]
    },
    {
        title:"Eshop Devices",
        lightBox:'eshop',
        subtitle:"Ecommerce",
        author:'OWN',
        tech:"TAILWINDCSS REACT NEXT MONGODB",
        portada:eshop1,
        link:"https://eshopdevices.com/",
        download:"",
        image:[eshop1,eshop2,eshop3,eshop4]
    },
    {
        title:"Sermar Aire",
        lightBox:'sermar',
        subtitle:"Landing WebSite",
        author:'OWN',
        tech:"REACT TYPESCRIP TAILWINDCSS NEXT",
        portada:Sermar1,
        link:"https://sermaraire.com",
        download:"",
        image:[Sermar2,Sermar3,Sermar4,Sermar5,Sermar6,Sermar7,Sermar8,Sermar9]
    },
    {
        title:"Ocular Cirugia",
        lightBox:'ocular',
        subtitle:"Landing WebSite",
        author:'OWN',
        tech:"REACT TAILWINDCSS NEXT",
        portada:ocular1,
        link:"https://ocularinsumosquirurgicos.com",
        download:"",
        image:[ocular1,ocular2,ocular3,ocular4,ocular5]
    },
    {
        title:"Central Cam",
        lightBox:'central',
        subtitle:"Ecommerce",
        author:'PROGRAMUNDO',
        tech:"TAILWINDCSS REACT NEXT MONGODB",
        portada:central1,
        link:"https://centralcamshop.com/",
        download:"",
        image:[central1,central2,central3,central4,central5,central6,central7]
    },
    {
        title:"Alarma Geográfica APP",
        lightBox:'AGA',
        subtitle:"Product WebSite",
        author:'OWN',
        tech:"HTML5 CSS3 JAVASCRIPT",
        portada:Alarma1,
        link:"https://alarma-geografica-web.vercel.app/",
        download:"",
        image:[Alarma2,Alarma3,Alarma4,Alarma5,Alarma6,Alarma7,Alarma8]
    },
    {
        title:"ROMIHSALE",
        lightBox:'ROMIHSALE',
        subtitle:"Landing WebSite",
        author:'OWN',
        tech:"REACT JAVASCRIPT TAILWIND CSS",
        portada: Romihsale1,
        link:"https://romihsale.com/",
        download:"",
        image:[Romihsale2,Romihsale3,Romihsale4,Romihsale5,Romihsale6,Romihsale7,Romihsale8]
    },
    {
        title:"Residencia Salomon",
        lightBox:'RSalomon',
        subtitle:"Landing Website",
        author:'PROGRAMUNDO',
        tech:"JAVASCRIPT REACT NEXT CHAKRA",
        portada:residencia1,
        link:"https://residenciasalomon.com/",
        download:"",
        image:[residencia2,residencia3,residencia4,residencia5,residencia6]
    },
    {
        title:"Od Torres Grau",
        lightBox:'ODTG',
        subtitle:"Landing WebSite",
        author:'OWN',
        tech:"REACT JAVASCRIPT CSS BOOTSTRAP",
        portada:DTG,
        link:"https://dtg-client.vercel.app/",
        download:"",
        image:[DTG]
    }
]